/**
 * Created by xxh on 2016/10/25.
 */

const JSON = window.JSON

export default {
  logout: function () {
    this.removeObject('NXCELLS_ACCESS_TOKEN')
    this.removeObject('NXCELLS_HISTORY_URL')
  },
  setObject: function (key, value) {
    if (typeof value === typeof {}) value = JSON.stringify(value)
    return window.localStorage.setItem(key, value)
  },
  getObject: function (key) {
    let value = window.localStorage.getItem(key) || ''
    if (/^\{.*\}$/.test(value)) value = JSON.parse(value)
    return value
  },
  removeObject: function (key) {
    window.localStorage.removeItem(key)
  },
  isParent: function (children, parent) {
    if (!parent) return false
    while (children) {
      if (children === parent) return true
      children = children.parentNode
    }
    return false
  },
  trim: function (str) {
    // 用正则表达式将前后空格
    // 用空字符串替代。
    return str.replace(/(^\s*)|(\s*$)/g, "");
  },
  newImage: function(){
    return new Image()
  },
  browsType:function(){
    let u = navigator.userAgent;
    let brows = {
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      Android: u.indexOf('Android') > -1,
      iPhone: u.indexOf('iPhone') > -1,
      NetCells_Android: u.indexOf('NetCells_Android') > -1,
      NetCells_iOS: u.indexOf('NetCells_iOS') > -1,
      MicroMessenger: u.indexOf('MicroMessenger') >-1, //微信浏览器
      Firefox: u.indexOf('Firefox') > -1, //火狐浏览器
      IE: u.indexOf('.NET CLR') > -1, //IE浏览器
      Mac: u.indexOf('Mac OS X') > -1, //苹果电脑访问有可能是iPad
      Windows: u.indexOf('Windows') > -1 //Windows电脑访问
    };

    if(brows.NetCells_Android){
      return 'AndroidClient';  //安卓客户端
    }else if(brows.NetCells_iOS){
      return 'iOSClient';   //iOS客户端
    }else if(brows.iPhone && !brows.NetCells_iOS && !brows.MicroMessenger){
      return 'iOSBrows';   //iPhone浏览器
    }else if(brows.Android && !brows.NetCells_Android && !brows.MicroMessenger){
      return 'AndroidBrows';   //Android浏览器
    }else if(brows.iPhone && brows.MicroMessenger){
      return 'iOSMicroMessenger';   //iPhone微信浏览器
    }else if(brows.Android && brows.MicroMessenger){
      return 'AndroidMicroMessenger';   //Android微信浏览器
    }else if(brows.Windows && !brows.mobile){
      return 'WindowsBrows';   //Windows电脑访问
    }else if(brows.Mac && !brows.mobile){
      return 'MacBrows';   //Mac电脑访问
    }else if(brows.IE){
      return 'IE';   //
    }else{
      return 'NotFound';
    }
  },
  browserLanguage:function () {
    let browserLanguage = (navigator.language || navigator.browserLanguage).toLowerCase();
    console.log(navigator)
    if(browserLanguage.indexOf('zh-cn') > -1){
      return 'zh-Hans'    //简体中文
    }else if (browserLanguage.indexOf('en') > -1){
      return 'en'         //英语
    }else if (browserLanguage.indexOf('ja') > -1){
      return 'ja'         //日语
    }else if (browserLanguage.indexOf('zh-hk' || 'zh-tw') > -1){
      return 'zh-Hant'    //繁体中文
    }else {
      return 'en'         //默认英语
    }
  }

}


