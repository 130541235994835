<template>
  <div style="position: relative;">
    <div style="color: #333;"
         :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'" >{{ config.name }}</div>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
export default {
  name: 'nxText',
  props: {       pageSetup: Object,
    value: String,
    config: Object,
    children: Array,
    field: Object,
    label: String,
    cssdiy: Object
  },
    data () {
        return {
            weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },
            sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },
            thumbUrl: ''
        }
    }
}
</script>

<style scoped>

</style>
