import { loadScript } from '@/utils/loadScript'
import utils from '@/utils/index'

export class NXBridge {
  constructor() {
    this.sqlite = {
      query: 'sqliteQuery',
      command: 'sqliteCommand'
    }
    this.init()
  }

  init() {
    // 判断是否是壳程序
      // console.log(navigator.userAgent)
    if (!/NetCells/i.test(navigator.userAgent)) {
      console.log('Current is not inside app')
      return
    }
    // 非PROD环境开启模拟手机控制台
    // webpack
    // if (process.env.NODE_ENV !== 'production') {
    //   loadScript("/js/vconsole.min.js", err => {
    //     if (err) {
    //       return
    //     }
    //     new window.VConsole();
    //   })
    // }
    // vite
    //   alert(process.env.NODE_ENV === 'development')
    if (process.env.NODE_ENV === 'development') {
      loadScript('/js/vconsole.min.js', (err) => {
        if (err) {
          return
        }
        new window.VConsole()
      })
    }
    if (this.isAndroid()) {
      this.androidFun((bridge) => {
        this.callback(bridge)
      })
    }

    if (this.isIOS()) {
      this.iosFun((bridge) => {
        this.callback(bridge)
      })
    }
    console.log('this.isHarmony():', this.isHarmony())
    if (this.isHarmony()) {
      this.harmonyFun((bridge) => {
        this.callback(bridge)
      })
    }
  }

  callback(bridge) {
    window.nativeBridge = bridge || {}
    // userAgent 中有离线版的标示
    if (/offline/i.test(navigator.userAgent)) {
      if (bridge) {
        this.createDefaultTable(bridge)
        window.handleBackPress = this.handleBackPress
      }
    }
  }

  isAndroid() {
    return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1
  }

  isIOS() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }
  isHarmony() {
    return /harmony/i.test(navigator.userAgent)
  }

  isInsideApp() {
    return /NetCells/i.test(navigator.userAgent)
  }

  isInsideOfflineApp() {
    return /NetCells/i.test(navigator.userAgent) && location.href.startsWith('file://')
  }

  iosFun(callback) {
    if (window.WKWebViewJavascriptBridge) {
      return callback(window.WKWebViewJavascriptBridge)
    }
    if (window.WKWVJBCallbacks) {
      return window.WKWVJBCallbacks.push(callback)
    }
    window.WKWVJBCallbacks = [callback]
    window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
  }

    androidFun(callback) {
        let messageHandlers = {};
        let responseCallbacks = {};
        let uniqueId = 1;
        function registerHandler(handlerName, handler) {
            messageHandlers[handlerName] = handler;
        }
        function callHandler(handlerName, data, responseCallback) {
            if (arguments.length === 2 && typeof data == 'function') {
                responseCallback = data;
                data = null;
            }
            doSend({ handlerName:handlerName, data:data }, responseCallback);
        }
        function doSend(message, responseCallback) {
            if (responseCallback) {
                const callbackId = 'cb_'+(uniqueId++)+'_'+new Date().getTime();
                responseCallbacks[callbackId] = responseCallback;
                message['callbackId'] = callbackId;
            }
            window.normalPipe.postMessage(JSON.stringify(message));
        }
        function handleMessageFromNative(messageJSON) {
            const message = JSON.parse(messageJSON);
            let responseCallback;
            if (message.responseId) {
                responseCallback = responseCallbacks[message.responseId];
                if (!responseCallback) {
                    return;
                }
                responseCallback(message.responseData);
                delete responseCallbacks[message.responseId];
            } else {
                if (message.callbackId) {
                    const callbackResponseId = message.callbackId;
                    responseCallback = function(responseData) {
                        doSend({ handlerName:message.handlerName, responseId:callbackResponseId, responseData:responseData });
                    };
                }
                const handler = messageHandlers[message.handlerName];
                if (!handler) {
                    console.log("WebViewJavascriptBridge: WARNING: no handler for message from Kotlin:", message);
                } else {
                    handler(message.data, responseCallback);
                }
            }
        }
        const bridge = window.WebViewJavascriptBridge = {
            registerHandler: registerHandler,
            callHandler: callHandler,
            handleMessageFromNative: handleMessageFromNative
        };
        callback(bridge)
    }
  // androidFun(callback) {
  //   if (window.WebViewJavascriptBridge) {
  //       alert(111)
  //     callback(window.WebViewJavascriptBridge)
  //   } else {
  //     document.addEventListener(
  //       'WebViewJavascriptBridgeReady',
  //       function () {
  //           alert(222)
  //           // alert(JSON.stringify(window.WebViewJavascriptBridge))
  //         callback(window.WebViewJavascriptBridge)
  //       },
  //       false
  //     )
  //   }
  // }

  harmonyFun(callback) {
    window._dsf = window._dsf || {}
    const bridge = {
      callHandler: function (method, args, cb) {
        var ret = ''
        if (typeof args == 'function') {
          cb = args
          args = {}
        }
        if (typeof cb == 'function') {
          window.dscb = window.dscb || 0
          var cbName = 'dscb' + window.dscb++
          window[cbName] = cb
          args['_dscbstub'] = cbName
        }
        console.log('cb: ', JSON.stringify(args), typeof cb == 'function')
        args = JSON.stringify(args || {})
        if (window._dswk) {
          ret = prompt(window._dswk + method, args)
        } else {
          if (typeof _dsbridge == 'function') {
            ret = window._dsbridge(method, args)
          } else {
            ret = window._dsbridge.call(method, args)
          }
        }
        return ret
      },
      registerHandler: function (name, fun) {
        if (typeof name == 'object') {
          Object.assign(window._dsf, name)
        } else {
          window._dsf[name] = fun
        }
      }
    }
    callback(bridge)
  }

  post(url) {
    return new Promise((resolve) => {
      this.handleSqliteQuery(url, resolve)
    })
  }

  get(url) {
    return new Promise((resolve) => {
      this.handleSqliteQuery(url, resolve)
    })
  }

  createDefaultTable(bridge) {
    const command =
      'CREATE TABLE IF NOT EXISTS "nx_data"( id INTEGER PRIMARY KEY, path TEXT UNIQUE NOT NULL, value TEXT, created_at TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP);'
    bridge.callHandler(this.sqlite.command, { table: 'nx_data', command: command }, (response) => {
      const result = response.data
      if (result.error_msg) {
        console.log(`[NXCELLS]:创建默认表nx_data失败。:${JSON.stringify(response)}`)
        return
      }
      console.log(`[NXCELLS]:默认表nx_data数据:${response.data}`)
    })
  }

  handleBackPress() {
    window.nativeBridge.registerHandler('backPress', function (data, responseCallback) {
      if (history.length > 1) {
        history.go(-1)
      }
      responseCallback({ nativeBack: history.length > 1 ? '' : 'back' })
    })
  }

  handleSqliteUpdate(url, json) {
    if (!/NetCells/i.test(navigator.userAgent)) {
      console.log('[NXCELLS] update: Current is not inside app')
      return
    }
    if (!url) {
      console.log('[NXCELLS] update: URL is not found!')
      return
    }
    // url = url.split("?")[0]
    const v = JSON.stringify(json)
    const command = `INSERT INTO nx_data (path, value) VALUES ('${url}', '${v}') ON CONFLICT(path) DO UPDATE SET value = '${v}'`
    window.nativeBridge.callHandler(
      'sqliteCommand',
      { table: 'nx_data', command: command },
      (response) => {
        // sqliteCommand 执行完成后会执行 select * ，response.data 会是整个表的数据
        console.log(`[NXCELLS] update: path=${url},value=${JSON.stringify(response.data)}`)
      }
    )
  }

  handleSqliteQuery(url, resolve) {
    // url = url.split("?")[0]
    const command = `select value from nx_data where path = '${url}'`
    window.nativeBridge.callHandler('sqliteQuery', { command: command }, (response) => {
      const v = response.data.value
      console.log(`[NXCELLS] query: path=${url},value=${v}`)
      const error = { Notices: null, NoticesCount: 0, Code: 400, Message: null, Data: null }
      resolve(utils.isEmpty(v) ? error : JSON.parse(v))
    })
  }
}
