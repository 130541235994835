import route from './route'

export default {
  route,
  toolbar: {
    appname: 'NXCELLS',
    settings: '設置',
    profile: '資料',
    logout: '退出'
  },
  login: {
    singIn: '登錄',
    singUp: '註冊',
    user: '賬號',
    password: '密碼',
    verifyCode: '驗證碼',
    userRequire: '請輸入賬號',
    passwordRequire: '請輸入密碼',
    verifyCodeRequire: '請輸入驗證碼',
    verifyCodeHelp: '看不清?',
    confirm: '確認',
    settings: '設置',
    domain: '服務器地址',
    lang: '語言',
    app: '應用系統',
    serverAddress: '請輸入伺服器地址',
    serverAccount: '請選擇賬套',
    serverErr: '輸入地址有誤，請聯繫管理員！'
  },
  application: {
    addTabError: '無法打開'
  },
  errors: {
    whoops: '哦嚯~!',
    back: '返回!',
    301: 'Moved Permanently',
    401: '未授權訪問',
    403: '禁止訪問',
    404: '頁面未找到',
    500: '內部服務器錯誤'
  }
}
