import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '../config'

// custom i18n
import en from './en'
import zh from './zh'
import tw from './tw'


Vue.use(VueI18n)

const messages = {
  en: {
    ...en
  },
  zh: {
    ...zh
  },
  tw: {
    ...tw
  }
}

export const locales = [
  {
    title: 'English',
    locale: 'en',
    abbr: 'ENG'
  },
  {
    title: '简体中文',
    locale: 'zh',
    abbr: 'CHN'
  },
  {
    title: '繁體中文',
    locale: 'tw',
    abbr: 'TW'
  }
]

const i18n = new VueI18n({
  locale: config.locale,
  messages
})

export function setLocale (locale) {
  if (i18n.locale !== locale) {
    console.log(`[NXCELLS] locale set to "${locale}"`)
    i18n.locale = locale || config.locale
  } else {
    console.warn(`[NXCELLS] locale "${locale}" is current`)
  }
}

export default i18n
