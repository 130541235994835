<template>
  <div style="position: relative;" v-if="field.Visable">
    <van-field :required="field.Nullable == true" :label="label"   :label-width="config.labelWidth"  :disabled="isEditSaved || !field.Editable"
               :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name }}</div>
      </template>
      <template #input>
        <van-switch size="26px"
                    :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'" ></van-switch>
      </template>
    </van-field>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
    import { Field, Switch } from 'vant'
    export default {
        name: 'nxMobileSwitch',
        components: {
            [Field.name]: Field,
            [Switch.name]: Switch
        },
        props: {       pageSetup: Object,
            value: String,
            label: String,
            config: Object,
            children: Array,
            mastertable: Object,
            tables: Array,
            cssdiy: Object,
            isEditSaved: Boolean
        },
        mounted () {
            const tableList = this.tables
            tableList.push(this.mastertable)
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                if (table.TableCode === this.tablecode) {
                    for (let j = 0; j < table.Fields.length; j++) {
                        if (table.Fields[j].FieldCode === this.config.FieldCode) {
                            this.field = table.Fields[j]
                        }
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        }
    }
</script>

<style scoped>

</style>
