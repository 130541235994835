import { render, staticRenderFns } from "./nxMobileFileList.vue?vue&type=template&id=53a853c6&scoped=true&"
import script from "./nxMobileFileList.vue?vue&type=script&lang=js&"
export * from "./nxMobileFileList.vue?vue&type=script&lang=js&"
import style0 from "./nxMobileFileList.vue?vue&type=style&index=0&id=53a853c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a853c6",
  null
  
)

export default component.exports