<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" :label="label"  :label-width="config.labelWidth"
               :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'"
             :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name }}</div>
      </template>
      <template #input>
        <van-rate :disabled="isEditSaved || !field.Editable" v-model="dataSet[tablecode][rowindex][config.FieldCode]"
                  :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'" ></van-rate>
      </template>
    </van-field>
  </div>
</template>

<script>
import { Field, Rate } from 'vant'
export default {
  name: 'nxMobileRate',
  components: {
    [Field.name]: Field,
    [Rate.name]: Rate
  },
  props: {       pageSetup: Object,
      mastertable: Object,
      tables: Array,
      dataSet: Object,
      config: Object,
      label: String,
      isEditSaved: Boolean,
      rowindex: Number,
      tablecode: String
  },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    }
}
</script>

<style scoped>

</style>
