<template>
  <div style="position: relative;">
    <van-divider  :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" ></van-divider>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
import { Field, Divider } from 'vant'
export default {
  name: 'nxMobileDivider',
  components: {
    [Field.name]: Field,
    [Divider.name]: Divider
  },
  props: {       pageSetup: Object,
    value: String,
    label: String,
    config: Object,
    children: Array,
    field: Object,
    cssdiy: Object,
      isEditSaved: Boolean
  }
}
</script>

<style scoped>

</style>
