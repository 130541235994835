import * as dayjs from 'dayjs'
const has = Object.prototype.hasOwnProperty
const toString = Object.prototype.toString

export default {
    IsNullOrEmpty: function (str) {
        if (str === undefined || str == null || str === '') {
            return true
        }
        return false
    },
  setStorage: (key, value, encode = true) => {
    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }
    if (encode) {
      value = window.btoa(encodeURIComponent(value))
    }
    window.localStorage.setItem(key, value)
  },
  getStorage: (key, decode = true) => {
      let value = window.localStorage.getItem(key) || ''
      if (/^\{.*\}$/.test(value)) value = JSON.parse(value)
      return value
  },
  clearLocalStorage: () => {
    window.localStorage.clear()
  },
  logout () {
    // const setting = this.getStorage('setting', true)
    // this.clearLocalStorage()
    // this.setStorage('setting', setting, true)
  },
    getGuid () {
        let d = new Date().getTime() // Timestamp
        let d2 =
            (performance && performance.now && performance.now() * 1000) || 0 // Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                let r = Math.random() * 16 // random number between 0 and 16
                if (d > 0) {
                    // Use timestamp until depleted
                    r = (d + r) % 16 | 0
                    d = Math.floor(d / 16)
                } else {
                    // Use microseconds since page-load if supported
                    r = (d2 + r) % 16 | 0
                    d2 = Math.floor(d2 / 16)
                }
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
            }
        )
    },
  scrollTop () {
    const scroll = function () {
      const c = document.documentElement.scrollTop || document.body.scrollTop
      if (c > 0) {
        window.requestAnimationFrame(scroll)
        window.scrollTo(0, c - c / 8)
      }
    }
    scroll()
  },
  isEmpty (val) {
    // Null and Undefined...
    if (val == null) return true

    // Booleans...
    if (typeof val === 'boolean') return false

    // Numbers...
    if (typeof val === 'number') return val === 0

    // Strings...
    if (typeof val === 'string') return val.length === 0

    // Functions...
    if (typeof val === 'function') return val.length === 0

    // Arrays...
    if (Array.isArray(val)) return val.length === 0

    // Errors...
    if (val instanceof Error) return val.message === ''

    // Objects...
    if (val.toString === toString) {
      switch (val.toString()) {
        // Maps, Sets, Files and Errors...
        case '[object File]':
        case '[object Map]':
        case '[object Set]': {
          return val.size === 0
        }

        // Plain objects...
        case '[object Object]': {
          for (const key in val) {
            if (has.call(val, key)) return false
          }

          return true
        }
      }
    }

    // Anything else...
    return false
  },
  deepClone (obj) {
    const _toString = Object.prototype.toString

    // null, undefined, non-object, function
    if (!obj || typeof obj !== 'object') {
      return obj
    }

    // DOM Node
    if (obj.nodeType && 'cloneNode' in obj) {
      return obj.cloneNode(true)
    }

    // Date
    if (_toString.call(obj) === '[object Date]') {
      return new Date(obj.getTime())
    }

    // RegExp
    if (_toString.call(obj) === '[object RegExp]') {
      const flags = []
      if (obj.global) { flags.push('g') }
      if (obj.multiline) { flags.push('m') }
      if (obj.ignoreCase) { flags.push('i') }

      return new RegExp(obj.source, flags.join(''))
    }

    const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {}

    for (const key in obj) {
      result[key] = this.deepClone(obj[key])
    }

    return result
  },
  dateFormat (fmt, date) {
    // https://dayjs.gitee.io/docs/zh-CN/display/format
    return dayjs(date).format(fmt)
  },
  getLabelWidth (cell, captionRate) {
    return 'width:' + ((cell.TitleColsCount * captionRate - 5) / cell.CellsCount) + '%; margin-right: ' + (5 / cell.CellsCount) + '%;'
  },
  debounce (delay, callback, arg) {
    let task
    return function () {
      clearTimeout(task)
      task = setTimeout(() => {
        callback.apply(this, [arg])
      }, delay)
    }
  },
  throttle (delay, callback) {
    let wait = false
    return function () {
      if (!wait) {
        callback.apply(this, arguments)
        wait = true
        setTimeout(() => {
          wait = false
        }, delay)
      }
    }
  },
  splitStr (content, chars) {
    const arr = []
    let sub = ''
    while (content.indexOf(chars) >= 0) {
      sub = content.substr(0, content.indexOf(chars))
      content = content.replace(sub + chars, '')
      arr.push(sub)
    }
    arr.push(content)
    return arr
  },
  parsePlainText: function (str) {
    str = str.replace('[', '')
    str = str.replace(']', '')
    return str
  },
  containsKey: function (dic, val) {
    if (val == null || val === undefined) {
      return JSON.stringify(dic) === '{}'
    }
    if (typeof (dic) === 'object') {
      return Object.prototype.hasOwnProperty.call(dic, val)
    }
    return false
  },
  isNumber: function (val) {
    return typeof val === 'number' && !isNaN(val)
  }
}
