import axios from 'axios'
import router from '@/router'
import '../plugins/nprogress/nprogress'
import '../plugins/nprogress/nprogress.css'
import utils from './index'
// import { Notification } from 'element-ui'

const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // 动态 server 不能在 instance 设置 baseURL
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  timeout: 80 * 1000
})

let server = ''
let token = ''

instance.interceptors.request.use(
  config => {
    token = utils.getStorage('NXCELLS_ACCESS_TOKEN')
    const setting = utils.getStorage('setting', true)
    const protocol = location.protocol + '//'
    if (process.env.NODE_ENV !== 'production') {
      // develop 模式下使用 .env.development 中配置的值
      // server = process.env.VUE_APP_PROXY_API
        server = process.env.VUE_APP_BASE_API
      if (/\/model\//.test(config.url)) {
        config.url = config.url.replace(protocol + setting.server, '')
        server = '/file'
      }
    } else {
      // release 模式下使用登录时选择的服务器
      server = document.location.protocol + '//' + document.location.host + '/api'
    }
    // console.log(server)
    if (config.url !== '/GetAccessToken') {
      config.url += `?access_token=${token}`
    }
    config.baseURL = server
    // console.info('[NXCELLS] axios config:', config)
    // window.NProgress.start()
    return config
  },
  error => {
    // window.NProgress.done()
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  response => {
    // window.NProgress.done()
    const data = response.data
    const responseType = response.request.responseType
    // 二进制数据则直接返回
    if (responseType === 'blob' || responseType === 'arraybuffer') {
      return response
    }
    // if (data.Code !== 0) {
    //   Notification({
    //     message: data.Message,
    //     type: 'error'
    //   })
    // }
    return data
  },
  error => {
    // window.NProgress.done()
    if (error.response) {
      // const data = error.response.data
      // console.error(`[NXCELLS] call api error: "${JSON.stringify(data)}"`)
      const code = error.response.status
      if (code === 401 || code === 403 || code === 408) {
        utils.logout()
        router.replace({ path: '/login' })
      }
        return Promise.reject(error.response)
        // return Promise.reject(data)
    }
    return Promise.reject(error)
  }
)

export default instance
