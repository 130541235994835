export default {
  home: '首頁',
  message: '我的消息',
  gtasks: '待辦任務',
  applications: '我的應用',
  business: '業務導航',
  render: 'form',
  errors: '錯誤',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500'
  },
  clipboard: '剪切板',
  externalLink: '外部鏈接'
}
