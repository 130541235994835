<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :required="field.Nullable == true" :label="label"   :label-width="config.labelWidth"  :disabled="isEditSaved || !field.Editable"
               :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name }}</div>
      </template>
      <template #input>
        <van-checkbox shape="square" v-model="dataSet[tablecode][rowindex][config.FieldCode]"   :disabled="isEditSaved || !field.Editable" @click="changeCheck"
                      :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; background-color: #fff; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'" ></van-checkbox>
      </template>
    </van-field>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
import { Field, Checkbox } from 'vant'
export default {
  name: 'nxMobileRadio',
  components: {
    [Field.name]: Field,
    [Checkbox.name]: Checkbox

  },
  props: {       pageSetup: Object,
      dataSet: Object,
      value: String,
      config: Object,
      children: Array,
      mastertable: Object,
      tables: Array,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      rowindex: Number,
      tablecode: String,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function,
      changeValue: Function
  },
    data () {
        return {
            weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null
        }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods: {
        async changeCheck() {
            if (this.isEditSaved || !this.field.Editable) {
                return
            }
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            const result = await this.editFieldBeforeV2()
            if (result) {
                this.editFieldAfter()
            } else {
                this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, !this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode])
            }
        },
    }
}
</script>

<style scoped>

</style>
