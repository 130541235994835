import route from './route'

export default {
  route,
  toolbar: {
    appname: 'NXCELLS',
    settings: 'Settings',
    profile: 'Profile',
    logout: 'Logout'
  },
  login: {
    singIn: 'Sing in',
    singUp: 'Sing up',
    user: 'Account',
    password: 'Password',
    verifyCode: 'Verify Code',
    userRequire: 'Account is required',
    passwordRequire: 'Password is required',
    verifyCodeRequire: 'Verify Code is required',
    verifyCodeHelp: 'Invisibility ?',
    confirm: 'Confirm',
    settings: 'Settings',
    domain: 'Domain',
    lang: 'Language',
    app: 'Application',
    serverAddress: 'Please input server address!',
    serverAccount: 'Please select account book!',
    serverErr: 'Server address error, please contact administrator！'
  },
  application: {
    addTabError: 'Can not open '
  },
  errors: {
    whoops: 'Whoops',
    back: 'Get me out of here!',
    301: 'Moved Permanently',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error'
  }
}
