import Vue from 'vue'
import nxText from './NxText/nxText'
import nxMobileNumber from './NxMobileNumber/nxMobileNumber'
import nxMobileDatetime from './NxMobileDatetime/nxMobileDatetime'
import nxMobileDatalist from './NxMobileDatalist/nxMobileDatalist'
import nxMobileInput from './NxMobileInput/nxMobileInput'
import nxMobileCheckbox from './NxMobileCheckbox/nxMobileCheckbox'
import nxMobileRadio from './NxMobileRadio/nxMobileRadio'
import nxMobileTable from './NxMobileTable/nxMobileTable'
import nxMobileTab from './NxMobileTab/nxMobileTab'
import nxMobileSwitch from './NxMobileSwitch/nxMobileSwitch'
import nxMobileRate from './NxMobileRate/nxMobileRate'
import nxMobileColor from './NxMobileColor/nxMobileColor'
import nxMobileSlider from './NxMobileSlider/nxMobileSlider'
import nxMobileButton from './NxMobileButton/nxMobileButton'
import nxMobileDivider from './NxMobileDivider/nxMobileDivider'
import nxMobileGroup from './NxMobileGroup/nxMobileGroup'
import nxMobileMycomponent from './NxMobileMycomponent/nxMobileMycomponent'
import nxMobilePicture from './NxMobilePicture/nxMobilePicture'
import nxMobilePictureList from './NxMobilePictureList/nxMobilePictureList'
import nxMobileVideo from './NxMobileVideo/nxMobileVideo'
import nxMobileFile from './NxMobileFile/nxMobileFile'
import nxMobileFileList from './NxMobileFileList/nxMobileFileList'
import placementarea from './placementarea/index'
import nxMobileSelect from './NxMobileSelect/nxMobileSelect'
import nxMobileAudioRecorder from './NxMobileAudioRecorder/nxMobileAudioRecorder'

const cptList = [
    nxText,
    nxMobileNumber,
    nxMobileDatetime,
    nxMobileDatalist,
    nxMobileInput,
    nxMobileCheckbox,
    nxMobileRadio,
    nxMobileTable,
    nxMobileSwitch,
    nxMobileRate,
    nxMobileColor,
    nxMobileSlider,
    nxMobileButton,
    nxMobileDivider,
    nxMobileGroup,
    nxMobileMycomponent,
    nxMobilePicture,
    nxMobileVideo,
    nxMobileFile,
    nxMobileFileList,
    placementarea,
    nxMobileSelect,
    nxMobileTab,
    nxMobilePictureList,
    nxMobileAudioRecorder
]

cptList.forEach(ele => {
  Vue.component(ele.name, ele)
})
