<template>
 <div  style="position: relative;" >
   <!-- eslint-disable -->
   <van-tabs  :ref="guid" class="myTab"  v-model="active":title-active-color="tabsColor" :style="{ '--tabs-color': tabsColor, '--line-width': tabWidth + 'px' }">
     <van-tab v-for="(component, i) in config.componentList" :title="component.title" :key="i" :name="i"
              :title-style="'margin-left:10px; margin-right:10px; font-size:' + sizeList[config.tabsSize] + 'px; font-weight:' + weightList[config.tabsWeight] + ';'">
       <div style="margin-top: 5px; background-color: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px #ccc;">
         <component
                 :is-tab="true"
                 :all-refreh-table="allRefreshTable"
                 :page-setup="pageSetup"
           :is="item.component"
           v-for="(item, index) in component.pageComponents"
           :key="index"
           :data-set="dataSet"
           :label="item.label"
           :config="item.setStyle"
           :id="item.id"
           :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' : item.setStyle.col === '1/4' ? '25%' : '100%'
                }"
           :is-edit-saved="isEditSaved"
           :is-query="IsQuery"
           :resource-home = 'resourceHome'
           :on-click-upload-image="onClickUploadImage"
           :pop-image-info="popImageInfo"
           :pop-affix-info="popAffixInfo"
           :preview-affix="previewAffix"
           :on-click-show-image="onClickShowImage"
           :remove-image="removeImage"
           :remove-affix="removeAffix"
           :change-value="changeValue"
           :rowindex = "0"
           :add-focuse-row="addFocuseRow"
           :edit-field-before="editFieldBefore"
           :edit-field-before-v2="editFieldBeforeV2"
           :edit-field-after="editFieldAfter"
           :on-click-upload-affix="onClickUploadAffix"
           :on-click-upload-video="onClickUploadVideo"
           :tablecode = "item.setStyle.TableCode"
           :field="item.setStyle.field"
           :tables="tables"
           :mastertable="mastertable"
           :link-report-v2 = "linkReportV2"
           :delete-focuse-row = "deleteFocuseRow"
           :do-action="doAction"
           :dic-all-checkbox-item="dicAllCheckboxItem"
           :show-focuse-table = "showFocuseTable"
           :tablerowindex="rowIndex"
           :add-focuse-next-row="addFocuseNextRow"
           :on-click-open-list="onClickOpenList"
                 :on-click-upload-image-list="onClickUploadImageList"
                 :on-click-upload-affix-list="onClickUploadAffixList"
           :sg-bar-code="sgBarCode"
           :refresh="refresh"
                 :show-preloader="showPreloader"
                 :hide-preloader="hidePreloader"
         >
         </component>
       </div>
     </van-tab>
   </van-tabs>
   <!-- 删除组件 -->
   <slot name="deles" />
 </div>
</template>

<script>
import { Tabs, Tab } from 'vant'
export default {
  name: 'nxMobileTab',
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab

  },
  props: {
      pageSetup: Object,
      mastertable: Object,
      tables: Array,
      value: String,
      config: Object,
      children: Array,
      field: Object,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      dataSet: Object,
      changeValue: Function,
      addFocuseRow: Function,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function,
      linkReportV2: Function,
      onClickOpenList: Function,
      sgBarCode: Function,
      dicAllCheckboxItem: Object,
      showFocuseTable: Boolean,
      rowIndex: Number,
      addFocuseNextRow: Function,
      deleteFocuseRow: Function,
      refresh: Boolean,
      IsQuery: Boolean,
      removeImageV2: Function,
      removeAffixV2: Function,
      editFieldAfterV2: Function,
      onClickUploadVideo: Function,
      doAction: Function,
      onClickUploadImageList: Function,
      onClickUploadAffixList: Function,
      showPreloader: Function,
      hidePreloader: Function,
      allRefreshTable: Array
  },
  watch: {
      config: {
          handler () {
              // this.active = Number(this.config.active)
              this.tabsColor = this.config.tabsColor
              this.$forceUpdate()
          },
          deep: true
      },
      active () {
          this.$nextTick(() => {
              this.calculateTabWidth()
          })
      }
  },
  data () {
    return {
        active: Number(this.config.active),
        guid: new Date().getTime(),
        weightList: {
            0: 'inherit',
            33: 'normal',
            66: 'bold',
            99: 'bolder'
        },
        sizeList: {
            0: '10',
            33: '14',
            66: '16',
            99: '20'
        },
        tabWidth: 40,
        tabsColor: this.config.tabsColor
    }
  },
    mounted () {
        console.log(this.sizeList)
        console.log(this.config)
        this.$nextTick(() => {
            this.calculateTabWidth()
        })
    },
    methods: {
        calculateTabWidth () {
            const tab = this.$refs[this.guid]// 假设你的标签有一个ref为tabRef
            console.log(tab.$el.querySelector('.van-tab--active'))
            const titleWidth = tab.$el.querySelector('.van-tab--active').querySelector('.van-tab__text--ellipsis').offsetWidth + 28
            this.tabWidth = titleWidth
            tab.select('.van-tabs__line').style('width', titleWidth).do()
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>
  /deep/.myTab  .van-tabs__line {
      width: var(--line-width);
      background-color: var(--tabs-color);
  }
  /deep/.van-tab{
      flex: none!important;
  }
</style>
