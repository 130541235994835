export default {
  switch: 'Switch',
  home: 'Home',
  message: 'Messages',
  gtasks: 'ToDoTask',
  applications: 'Applications',
  business: 'Business',
  render: 'form',
  errors: 'Errors',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500'
  },
  clipboard: 'Clipboard',
  externalLink: 'ExternalLink'
}
