<template>
  <div class="bottom_btn">
    <van-tabbar v-model="active" active-color="'#017CFF'" inactive-color="#666666">
      <van-tabbar-item
              v-for="(item, index) in tabbars"
              :key="index"
              :to="item.name"
              :badge="badges[index] == 0 ? null : badges[index]"
              style="border-top: 2px solid #F2F3F5;"
      >
            <span style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 14px;text-align: center;font-style: normal;"
                  :style="active === index ? 'color:#017CFF' : 'color:#666666'" :class="index == 2 ? 'tui' : ''">
               {{ item.title }}
            </span>
          <template slot="icon" slot-scope="props">
              <img :class="index == 2 ? 'tui' : ''"  :src="props.active ? item.active : item.normal" height="25px;"/>
          </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
    import appHelper from '../../com/helper'
    import { Tabbar, TabbarItem } from "vant";
    import { constantRoutes } from '../../router'
    import {formatTime} from '../../utils/filters'
export default {
  name: 'NxFooter',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
    data() {
        return {
            currIndex: 2,
            active: 2,
            activeRoute: '/home',
            tabbars: [],
            badges:[0, 0, 0, 0, 0],
            messageconditions:{},
            taskconditions: {},
            showChartView: false
        };
    },
    props:{
        showbadges:{
            type:Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: true
        }
    },
    computed: {
    },
    watch: {
        '$route.path' (v) {
            console.log(v)
            this.activeRoute = v
            switch (this.activeRoute) {
                case '/home':
                    this.active = 2;
                    break;
                case '/message':
                    this.active = 0;
                    break;
                case '/gtasks':
                    this.active = 1;
                    break;
                case '/App':
                case '/app':
                    this.active = 3;
                    break;
                case '/me':
                    this.active = 4;
                    break;

            }
        },
        'showbadges' (v) {
            console.log(v)
            this.changeBadges()
        }
    },
    async created () {
        const result = await this.$api.getMenuChartList()
        if (result.Code == 0) {
            this.showChartView = result.CategoryItems.length > 0
        }
        if (!appHelper.getObject('NXCELLS_ACCESS_TOKEN')) {
            this.$router.push('login')
        }
        const p = this.$route.path
        this.activeRoute = p === '/' ? '/home' : p
        console.log(this.activeRoute)
        switch (this.activeRoute) {
            case '/home':
                this.active = 2;
                break;
            case '/message':
                this.active = 0;
                break;
            case '/gtasks':
                this.active = 1;
                break;
            case '/App':
            case '/app':
                this.active = 3;
                break;
            case '/me':
                this.active = 4;
                break;

        }
        this.tabbars = constantRoutes[1].children
        let currentDate = new Date().getTime()
        let BeginTime = formatTime(currentDate - 7 * (24 * 60 * 60 * 1000), 'yyyy/MM/dd hh:mm:ss')
        let EndTime = formatTime(currentDate, 'yyyy/MM/dd hh:mm:ss')
        var userInfo = appHelper.getObject('NXCELLS_USERINFO')
        this.messageconditions.UserName = userInfo.UserName
        this.messageconditions.NoticeType = 0;
        this.messageconditions.BeginTime = BeginTime
        this.messageconditions.EndTime = EndTime
        this.taskconditions.TaskStatus = "0,2";
        const taskListCount = await this.$api.getTaskListCount(this.taskconditions)
        this.$set( this.badges, 1 , taskListCount.TotalCount)
        const noticeListCount = await this.$api.getNoticeListCount(this.messageconditions)
        this.$set( this.badges, 0 , noticeListCount.NoticesCount)
    },
    async mounted() {
        const taskListCount = await this.$api.getTaskListCount(this.taskconditions)
        this.$set( this.badges, 1 , taskListCount.TotalCount)
        const noticeListCount = await this.$api.getNoticeListCount(this.messageconditions)
        this.$set( this.badges, 0 , noticeListCount.NoticesCount)
    },
    methods: {
      async changeBadges(){
          const taskListCount = await this.$api.getTaskListCount(this.taskconditions)
          this.$set( this.badges, 1 , taskListCount.TotalCount)
          const noticeListCount = await this.$api.getNoticeListCount(this.messageconditions)
          this.$set( this.badges, 0 , noticeListCount.NoticesCount)
      }
    },
}
</script>
<style lang="less" scoped>
    .bottom_btn /deep/.van-tabbar{
        height: 60px;
    }
    .bottom_btn /deep/.van-tabbar-item__icon img{
        height: 24px;
    }
    .bottom_btn /deep/.van-tabbar-item__icon .tui{
        height: 40px;
        width: 40px;
    }
    .tui{
        width: 40px;
        height: 40px;
        margin-top: -25px;
        border-radius: 50%;
        /*z-index: 10;*/
    }
    [class*=van-hairline]::after{
        border:none!important
    }
</style>
