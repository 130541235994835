<template>
  <div style="position: relative;">
    <!--<van-uploader v-model="imageArray" multiple :before-read="onClickUploadImageBefore" :after-read="onClickUploadImage1" :before-delete ="removeImageList" />-->
    <van-uploader accept="*/*" :preview-full-image="false" @click-preview="onClickPreview" v-model="fileList" multiple @click-upload="onClickUploadImageBefore" @change="changeUploadImage"  :after-read="onClickUploadAffix1" :before-delete ="removeAffixV2" :disabled="isEditSaved || !field.Editable">
      <van-button icon="plus" type="primary">上传文件</van-button>
      <template #preview-cover="{ fileName }">
        <div class="preview-cover van-ellipsis">{{ fileName }} </div>
      </template>
    </van-uploader>
  </div>
</template>

<script>
import { Uploader, Loading, Image, Button } from 'vant'
import {IMAGE_ROOT} from '../../../../com/config'
export default {
    name: 'nxMobileFileList',
    components: {
        [Image.name]: Image,
        [Button.name]: Button,
        [Uploader.name]: Uploader,
        [Loading.name]: Loading

    },
    props: {
        pageSetup: Object,
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        onClickUploadImageList: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        onClickUploadAffixList: Function,
        onClickUploadAffix: Function,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        onClickUploadImageBeforeV2: Function,
        removeImageList: Function,
        dataRow: Object,
        deleteFocuseRow: Function,
        isList: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        },
        previewAffix: Function,
        showPreloader: Function,
        hidePreloader: Function
    },
    watch: {
        refresh () {
            if (this.deleteIndex > -1) {
                this.fileList.splice(this.deleteIndex, 1)
                this.deleteIndex = -1
                // this.imageArray = []
            }
        },
        dataSet: {
            async handler () {
                // console.log(this.config.TableCode)
                // this.imageArray = []
                for (let i = this.fileList.length - 1; i >= 0; i--) {
                    if (this.fileList[i].file !== undefined || this.fileList[i].content !== undefined) {
                        this.fileList.splice(i, 1)
                    }
                }
                for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                    if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                        if (this.dataSet[this.config.TableCode][i][this.config.OutFieldCode] !== this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode]) {
                            let index = this.fileList.findIndex(element => element.id ===  this.dataSet[this.config.TableCode][i][this.config.FieldCode + '_id'])
                            if (this.fileList[i] !== undefined || index >= 0) {
                                this.fileList.splice(index, 1)
                            }
                            continue
                        }
                    }
                    console.log(this.dataSet)
                   const result = await this.$api.getImageOrAffixUrl({ ResourceID: this.dataSet[this.config.TableCode][i][this.config.FieldCode + '_id'], ReportID:0 })
                    if (result.Code !== 0) {
                        console.log(result)
                        continue
                    }
                    let index = this.fileList.findIndex(element => element.url === (IMAGE_ROOT + result.affixinfo.Url))
                    if (this.fileList[i] !== undefined || index >= 0) {
                        continue
                    }
                    this.fileList.push({
                        url: IMAGE_ROOT + result.affixinfo.Url ,
                        status: '',
                        message: '',
                        fileName : result.affixinfo.FileName,
                        id: this.dataSet[this.config.TableCode][i][this.config.FieldCode + '_id']
                    })
                }
            },
            deep: true
        }
    },
    data () {
      return {
          field: null,
          deleteIndex: -1,
          fileList: [],
          id: ''
      }
    },
    async mounted () {
        this.id = this.$utils.getGuid()
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if (table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                    break
                }
            }
            if (this.field !== null) {
                break
            }
        }
        this.fileList = []
        for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
            if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                if (this.dataSet[this.config.TableCode][i][this.config.OutFieldCode] !== this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode]) {
                    continue
                }
            }
            const result = await this.$api.getImageOrAffixUrl({ ResourceID: this.dataSet[this.config.TableCode][i][this.config.FieldCode], ReportID:0 })
            if (this.fileList[i] !== undefined) {
                continue
            }
            this.fileList.push({
                url: IMAGE_ROOT + result.affixinfo.Url ,
                status: '',
                message: '',
                fileName: result.affixinfo.FileName,
                id: this.dataSet[this.config.TableCode][i][this.config.FieldCode]
            })
        }
        console.log(this.field)
    },
    methods: {
        onClickPreview (file) {
          this.previewAffix(file.url)
        },
        removeAffixV2 (file, detail) {
            this.deleteIndex = -1
            if (this.isEditSaved || !this.field.Editable) {
                return false
            }
            // this.editFieldBefore(this.config.TableCode, this.dataSet[this.config.TableCode].length, this.config.FieldCode)
            let index = detail.index
            if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                    if (this.dataSet[this.config.TableCode][i][this.config.FieldCode] === file.id) {
                        index = i
                        break
                    }
                }
            }
            this.editFieldBefore(this.config.TableCode, index, this.config.FieldCode)
            this.deleteIndex = detail.index
            this.deleteFocuseRow(index, this.config.TableCode)
        },
        onClickUploadImageBefore () {
            // this.showPreloader()
            // this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            // this.onClickUploadImageBeforeV2()
        },
        changeUploadImage () {
            console.log(1122)
        },
        async onClickUploadAffix1 (e) {
            this.editFieldBefore(this.config.TableCode, this.dataSet[this.config.TableCode].length, this.config.FieldCode)
            const result = await this.editFieldBeforeV2()
            if (result) {
                if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                    this.onClickUploadAffixList(e, this.config.OutFieldCode, this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode])
                } else {
                    this.onClickUploadAffixList(e)
                }
            }
            this.hidePreloader()
        }
    }
}
</script>

<style scoped>
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
</style>
