export default {
  home: '首页',
  message: '我的消息',
  gtasks: '待办任务',
  applications: '我的应用',
  business: '业务导航',
  render: 'form',
  errors: '错误',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500'
  },
  clipboard: '剪切板',
  externalLink: '外部链接'
}
