import route from './route'

export default {
  route,
  toolbar: {
    appname: 'NXCELLS',
    settings: '设置',
    profile: '资料',
    logout: '退出'
  },
  login: {
    singIn: '登录',
    singUp: '注册',
    user: '账号',
    userRequire: '请输入账号',
    password: '密码',
    passwordRequire: '请输入密码',
    verifyCode: '验证码',
    verifyCodeRequire: '请输入验证码',
    verifyCodeHelp: '看不清?',
    confirm: '确认',
    settings: '设置',
    domain: '服务器地址',
    lang: '语言',
    app: '应用系统',
    serverAddress: '请输入服务器地址',
    serverAccount: '请选择账套',
    serverErr: '输入地址有误，请联系管理员！'
  },
  application: {
    addTabError: '无法打开'
  },
  errors: {
    whoops: '哦嚯~!',
    back: '返回!',
    301: 'Moved Permanently',
    401: '未授权访问',
    403: '禁止访问',
    404: '页面未找到',
    500: '内部服务器错误'
  }
}
