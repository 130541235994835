<template>
  <div style="position: relative;"  v-if="field.Visable">
    <van-field :label="label" :required="field.Nullable == true" :disabled="isEditSaved || !field.Editable"  :label-width="config.labelWidth"
               :style="'mini-height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'"
             :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name }}</div>
      </template>
      <template #input>
        <van-row style="width: 100%"  v-if="config.checkType === '2'">
          <van-col :span="24/(config.columnsCount === 0 ? 1 : config.columnsCount)" style="margin-top: 10px;" v-for="(item, index) in dataSet[tablecode][rowindex][config.modelField].split(',')" :key="index">
            <van-checkbox shape="square" v-if="item !== ''"  :name="item" :disabled="isEditSaved || !field.Editable"
                          v-model="dataSet[tablecode][rowindex][field.FieldCode] !== undefined && dataSet[tablecode][rowindex][field.FieldCode].split(',').indexOf(item) > -1"
                          @click="changeCheck(tablecode, config.FieldCode, rowindex, item)"
            >{{item}}</van-checkbox>
          </van-col>
        </van-row>
        <van-row style="width: 100%" v-else>
          <van-col :span="24/(field.Checkbox.ColumnsCount === 0 ? 1 : field.Checkbox.ColumnsCount)" style="margin-top: 10px;" v-for="(item, index) in dicAllCheckboxItem[field.FieldID]" :key="index">
            <van-checkbox shape="square" v-if="item !== ''"  :name="item" :disabled="isEditSaved || !field.Editable"
                          v-model="dataSet[tablecode][rowindex][field.FieldCode] !== undefined && dataSet[tablecode][rowindex][field.FieldCode].split(',').indexOf(item) > -1"
                          @click="changeCheck(tablecode, config.FieldCode, rowindex, item)"
            >{{item}}</van-checkbox>
          </van-col>
        </van-row>
      </template>
    </van-field>
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
</template>

<script>
    import { Field, Row, Col, Checkbox } from 'vant'
    export default {
        name: 'nxMobileCheckbox',
        components: {
            [Field.name]: Field,
            [Row.name]: Row,
            [Col.name]: Col,
            [Checkbox.name]: Checkbox
        },
        props: {       pageSetup: Object,
            dataSet: Object,
            value: String,
            config: Object,
            children: Array,
            mastertable: Object,
            tables: Array,
            cssdiy: Object,
            label: String,
            isEditSaved: Boolean,
            onClickUploadImage: Function,
            popImageInfo: Function,
            onClickShowImage: Function,
            removeImage: Function,
            removeAffix: Function,
            resourceHome: String,
            rowindex: Number,
            tablecode: String,
            editFieldBefore: Function,
            editFieldBeforeV2: Function,
            popAffixInfo: Function,
            previewAffix: Function,
            onClickUploadAffix: Function,
            dicAllCheckboxItem: Object,
            changeValue: Function
        },
        data () {
            return {
                weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null
            }
        },
        watch: {
            dicAllCheckboxItem () {
                console.log(this.dicAllCheckboxItem)
            }
        },
        mounted () {
            const tableList = this.tables
            tableList.push(this.mastertable)
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                if (table.TableCode === this.tablecode) {
                    for (let j = 0; j < table.Fields.length; j++) {
                        if (table.Fields[j].FieldCode === this.config.FieldCode) {
                            this.field = table.Fields[j]
                        }
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        },
        methods: {
            async changeCheck(tablecode, fieldCode, rowindex, item) {
                if (this.isEditSaved || !this.field.Editable){
                    return
                }
                this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
                const result = await this.editFieldBeforeV2()
                if (result) {
                    console.log(this.config)
                    if (!this.config.isRadio || (this.field.Checkbox !== null && this.field.Checkbox.AllowMultSelect)) {
                        const values = this.dataSet[tablecode][rowindex][fieldCode] === undefined ?  [] : this.dataSet[tablecode][rowindex][fieldCode].split(',')
                        if (values.indexOf(item) > -1) {
                            values.splice(values.indexOf(item), 1)
                        } else {
                            values.push(item)
                        }
                        let value = ''
                        for (let i = 0; i < values.length; i++) {
                            if (values[i] != "") {
                                value += (values[i] + ',')
                            }
                        }
                        if (values.length > 0) {
                            value = value.substring(0, value.length - 1)
                        }
                        this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, value)
                    } else {
                        if (this.dataSet[this.tablecode][this.rowindex][this.config.FieldCode] === item) {
                            this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, '')
                        } else {
                            this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, item)
                        }
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
